<template>
    <div class="container-fluid px-0">
        <div class="row row-cols-1 row-cols-lg-2 mb-n4">
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Sizes</div>
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-auto text-center" v-for="(size, index) in sizes" :key="index">
                                <img src="/assets/images/avatar.png" alt="image" :class="[`img-fluid avatar-${size}`]">
                                <p class="mt-2 small">{{ size.toUpperCase() }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Rounded</div>
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-auto text-center" v-for="(size, index) in sizes" :key="index">
                                <img src="/assets/images/avatar.png" alt="image" :class="[`img-fluid rounded avatar-${size} mb-2`]">
                            </div>
                        </div>
                        <div class="row align-items-center mt-4">
                            <div class="col-auto text-center" v-for="(size, index) in sizes" :key="index">
                                <img src="/assets/images/avatar.png" alt="image" :class="[`img-fluid rounded-circle avatar-${size} mb-2`]">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Avatar Title</div>
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-auto text-center">
                                <div class="avatar-xs mb-4">
                                    <span class="avatar-title rounded">XS</span>
                                </div>
                            </div>
                            <div class="col-auto text-center">
                                <div class="avatar-sm mb-4">
                                    <span class="avatar-title">SM</span>
                                </div>
                            </div>
                            <div class="col-auto text-center">
                                <div class="avatar-md mb-4">
                                    <span class="avatar-title rounded">MD</span>
                                </div>
                            </div>
                            <div class="col-auto text-center">
                                <div class="avatar-lg mb-4">
                                    <span class="avatar-title">LG</span>
                                </div>
                            </div>
                            <div class="col-auto text-center">
                                <div class="avatar-xl mb-4">
                                    <span class="avatar-title rounded-circle">XL</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Background Color</div>
                    <div class="card-body">
                        <div class="card-body">
                            <div class="row align-items-center">
                                <div class="col-auto text-center">
                                    <div class="avatar-xs mb-4">
                                        <span class="avatar-title rounded">XS</span>
                                    </div>
                                </div>
                                <div class="col-auto text-center">
                                    <div class="avatar-sm mb-4">
                                        <span class="avatar-title bg-secondary">SM</span>
                                    </div>
                                </div>
                                <div class="col-auto text-center">
                                    <div class="avatar-md mb-4">
                                        <span class="avatar-title bg-warning rounded">MD</span>
                                    </div>
                                </div>
                                <div class="col-auto text-center">
                                    <div class="avatar-lg mb-4">
                                        <span class="avatar-title bg-danger">LG</span>
                                    </div>
                                </div>
                                <div class="col-auto text-center">
                                    <div class="avatar-xl mb-4">
                                        <span class="avatar-title bg-success rounded-circle">XL</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Avatars',
        data() {
            return {
                sizes: ['xs', 'sm', 'md', 'lg', 'xl']
            }
        }
    }
</script>
